import { useState, useEffect } from "react";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { requireAuth, useAuth } from "./../util/auth";
import { useItemsByOwner, createItem, deleteItem } from "@/util/db";
import { apiRequest } from "../util/util";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus, Trash2, Send } from "lucide-react";
import { Input } from "@/components/ui/input";

const greetingTime = require("greeting-time");

function DashboardPage() {
  const auth = useAuth();

  const [composingMinderText, setComposingMinderText] = useState("");
  const [composeDialogIsOpen, setComposeDialogIsOpen] = useState(false);

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth?.user.uid);

  const [apiProfile, setApiProfile] = useState(null);

  function refreshProfile() {
    apiRequest("profile", "GET").then((result) => {
      setApiProfile(result);
    });
  }

  useEffect(() => {
    refreshProfile();
  }, []);

  return (
    <>
      <header className="py-8 container">
        <div className="w-full grid grid-cols-2 gap-4 content-start items-center justify-center">
          <div className="col-span-2">
            <div className="text-4xl font-bold">
              {"🍔 " + greetingTime(new Date())}
            </div>
          </div>
        </div>
      </header>
      <main className="py-8 container">
        <Card className="col-span-2">
          <CardHeader className="relative">
            <CardTitle>Minder items</CardTitle>
            <Dialog
              open={composeDialogIsOpen}
              onOpenChange={() => {
                setComposeDialogIsOpen(!composeDialogIsOpen);
              }}
            >
              <DialogTrigger asChild>
                <Button className="absolute right-8 top-4 m-2">
                  <Plus />
                </Button>
              </DialogTrigger>
              {/* TODO, factor all this into its own component */}
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Add Item</DialogTitle>
                  <DialogDescription>Add a new minder item.</DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Input
                      id="name"
                      defaultValue="Send a thank you card to Mom."
                      value={composingMinderText}
                      onChange={(event) => {
                        setComposingMinderText(event.target.value);
                      }}
                      className="col-span-4"
                    />
                  </div>
                </div>
                <DialogFooter>
                  <Button
                    type="submit"
                    onClick={() => {
                      console.log("sub");
                      createItem({
                        owner: auth!.user.uid,
                        name: composingMinderText,
                      }).then(() => {
                        setComposeDialogIsOpen(false);
                      });
                    }}
                  >
                    <Send />
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <CardDescription>
              TODO very soon: turn this into a DataTable
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            {itemsStatus !== "loading" &&
              items &&
              items.length > 0 &&
              items.map((item, index) => (
                <div className="border rounded p-4 flex items-center gap-6">
                  {item.name}
                  <Button className="ml-auto p-4">
                    <Trash2
                      onClick={() => {
                        deleteItem(item.id);
                      }}
                    />
                  </Button>
                </div>
              ))}
          </CardContent>
        </Card>
      </main>
    </>
  );
}

export default requireAuth(DashboardPage);
